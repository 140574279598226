<template>
  <b-form-group
    :label-for="id"
    :label-cols-md="column"
  >
    <template v-if="label" v-slot:label>
      <span style="font-size: 1rem; font-weight: 600;">
        {{ label }}
      </span>
    </template>
    <div v-if="tagLine" class="tag-line" v-html="tagLine" />
    <div
      :id="id"
      :style="customStyle"
      :class="customClass"
    >
      <slot>
        {{ formValue || '-' }}
      </slot>
    </div>
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
  },
  props: {
    // Got value from v-model
    value: {
      type: [String, Number],
      required: false,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    tagLine: {
      type: String,
      required: false,
      default: '',
    },
    column: {
      type: String,
      required: false,
      default: '0',
    },
    customStyle: {
      type: String,
      required: false,
      default: '',
    },
    customClass: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    formValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.tag-line {
  font-size: 0.875rem;
  font-weight: 500;
  color: #808080;
  margin-bottom: 0.75rem;
}
</style>
